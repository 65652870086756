@use '~@angular/material' as mat;
///////////////////////////////////
// SIDENAV
///////////////////////////////////

@mixin ms-sidenav-theme($theme) {
  $background: mat.get-color-from-palette($theme, "background");
  $foreground: mat.get-color-from-palette($theme, "foreground");

  .ms-sidenav {
    .ms-sidemenu {
      a {
        color: mat.get-color-from-palette($foreground, text);
      }

      .ms-sidemenu-item {
        &:hover {
          background: mat.get-color-from-palette($background, hover);
        }

        &.open {
          background: rgba(0, 0, 0, 0.02);
        }

        // &.active {
        //   background: #f5f3f3;
        //   > a {
        //     color: mat.get-color-from-palette(map-get($theme, accent));
        //   }
        // }
      }
    }

    .ms-sidebar-header {
      color: mat.get-color-from-palette($foreground, text);
      background: mat.get-color-from-palette($background, app-bar);
      border-bottom: 1px solid $border-color;
    }
  }
}

.ms-sidenav {
  position: absolute;
  overflow-x: hidden;
  transition: swift-ease-out(width); // Only set width property

  @include mat.elevation(2);

  &.mat-drawer-side {
    border-width: 0;
  }

  .ms-sidemenu {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    .ms-sidemenu-item {
      display: block;
      height: auto;
      padding: 0;

      &.open {
        > .ms-sidemenu {
          max-height: 2000px;
        }

        > .ms-sidemenu-toggle > .menu-caret {
          transform: rotate(-180deg);
        }
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      padding: 0 16px;
      text-decoration: none;
    }

    .menu-icon {
      width: 18px;
      height: 18px;
      margin-right: 16px;
      font-size: 18px;
      line-height: 18px;

      [dir="rtl"] & {
        margin-left: 16px;
        margin-right: auto;
      }
    }

    .menu-name,
    .menu-label,
    .menu-badge {
      transition: swift-ease-out(opacity);
    }

    .menu-label,
    .menu-badge {
      @include badge();
    }

    .menu-badge {
      border-radius: 10px;
    }

    .menu-caret {
      display: block;
      text-align: center;
      transition: fast-out-slow(transform);
    }

    &.level-0 > li > a > .menu-name {
      margin-right: 5px;

      [dir="rtl"] & {
        margin-left: 5px;
        margin-right: auto;
      }
    }

    &.level-1 > li > a {
      padding-left: 50px;

      [dir="rtl"] & {
        padding-left: 16px;
        padding-right: 50px;
      }
    }

    &.level-2 > li > a {
      padding-left: 64px;

      [dir="rtl"] & {
        padding-left: 16px;
        padding-right: 64px;
      }
    }

    &.submenu {
      max-height: 0;
      padding-top: 0;
      overflow: hidden;
      transform: translateZ(0) !important;
      transition: fast-out-slow(max-height);

      .ms-sidemenu-item {
        border-left-width: 0;
      }
    }
  }

  .ms-sidebar-header {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: $toolbar-height-desktop;
    padding: 0 10px;
    overflow: hidden;

    @include bp-lt(small) {
      & {
        height: $toolbar-height-mobile;
      }
    }

    // Colorful
    .ms-header-white & {
      background-color: white;
    }
  }

  .ms-sidebar-main {
    height: calc(100% - #{$toolbar-height-desktop});
    overflow: auto;

    @include bp-lt(small) {
      & {
        height: calc(100% - #{$toolbar-height-mobile});
      }
    }
  }
}

// User panel
.ms-user-panel {
  padding: 16px 0;
  border-bottom: 1px solid $border-color;
}

.ms-user-panel-avatar {
  margin-bottom: 8px;
  // Set default width and height can avoid flashing before avatar image loaded.
  width: 64px;
  height: 64px;
  border-radius: 999px;
  transition: swift-ease-out(transform);
}

.ms-user-panel-name,
.ms-user-panel-email {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: normal;
}

.ms-user-panel-name,
.ms-user-panel-email,
.ms-user-panel-icons {
  opacity: 1;
  transition: swift-ease-out(opacity);
}

.ms-user-panel-icons {
  white-space: nowrap;

  .mat-icon-button {
    width: 24px;
    height: 24px;
    line-height: 24px;

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

// Layout control
.ms-sidenav-collapsed,
.ms-sidenav-collapsed-fix {
  .ms-sidenav {
    width: $sidenav-collapsed-width;

    .menu-name,
    .menu-label,
    .menu-badge,
    .menu-caret,
    .ms-user-panel-name,
    .ms-user-panel-email,
    .ms-user-panel-icons {
      opacity: 0;
    }

    .ms-user-panel-avatar {
      transform: scale(0.5);
    }

    &:hover {
      width: $sidenav-width;

      .menu-name,
      .menu-label,
      .menu-badge,
      .menu-caret,
      .ms-user-panel-name,
      .ms-user-panel-email,
      .ms-user-panel-icons {
        opacity: 1;
      }

      .ms-user-panel-avatar {
        transform: scale(1);
      }
    }
  }
}

.ms-sidenav-collapsed {
  .ms-content-wrap {
    margin-left: $sidenav-collapsed-width !important;

    [dir="rtl"] & {
      margin-left: auto !important;
      margin-right: $sidenav-collapsed-width !important;
    }
  }

  &[dir="rtl"] .ms-content-wrap {
    margin-left: auto !important;
    margin-right: $sidenav-collapsed-width !important;
  }
}
