@use '~@angular/material' as mat;
@use '@angular/material/theming' as mat-th;
///////////////////////////////////////////////////////////////////////
// Material color helpers including text color and background color
///////////////////////////////////////////////////////////////////////

$mat-colors: (
  red: mat.$red-palette,
  pink: mat.$pink-palette,
  purple: mat.$purple-palette,
  deep-purple: mat.$deep-purple-palette,
  indigo: mat.$indigo-palette,
  blue: mat.$blue-palette,
  light-blue: mat.$light-blue-palette,
  cyan: mat.$cyan-palette,
  teal: mat.$teal-palette,
  green: mat.$green-palette,
  light-green: mat.$light-green-palette,
  lime: mat.$lime-palette,
  yellow: mat.$yellow-palette,
  amber: mat.$amber-palette,
  orange: mat.$orange-palette,
  deep-orange: mat.$deep-orange-palette,
  brown: mat.$brown-palette,
  gray: mat.$gray-palette,
  blue-gray: mat.$blue-gray-palette,
  white: white,
  black: black,
  light: mat-th.$light-primary-text,
  dark: mat-th.$dark-primary-text,
);

@mixin generate-color($prefix, $property) {
  @each $name, $value in $mat-colors {
    // If the value is a map, continue to each
    @if type-of($value) == "map" {
      @each $hue, $color in $value {
        @if ($hue != "contrast") {
          .#{$prefix + "-" + $name + "-" + $hue} {
            #{$property}: $color !important;
          }
        }
        // alias
        @if ($name == "gray") {
          .#{$prefix + "-grey-" + $hue} {
            @extend .#{$prefix + "-gray-" + $hue} !optional;
          }
        }

        @if ($name == "blue-gray") {
          .#{$prefix + "-blue-grey-" + $hue} {
            @extend .#{$prefix + "-blue-gray-" + $hue} !optional;
          }
        }
      }
    }

    @if type-of($value) == "color" {
      .#{$prefix + "-" + $name} {
        #{$property}: $value !important;
      }
    }
  }
}

@include generate-color("text", "color");
@include generate-color("bg", "background-color");

$main-color: #44034d;

.text-main {
  color: $main-color !important;
}
.bg-main {
  background-color: $main-color !important;
}
