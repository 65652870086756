@import "~primeng/resources/themes/md-light-deeppurple/theme.css";
@import "~primeng/resources/primeng.css";
@import "~primeicons/primeicons.css";
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 700 32px / 48px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 600 25px / 32px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 500 calc(16px * 0.83) / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 500 calc(16px * 0.67) / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 500 16px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 96px / 96px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 0em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 300 56px / 56px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 300 45px / 48px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 300 34px / 40px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 500 16px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-card {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-card-title {
  font-size: 32px;
  font-weight: 600;
}

.mat-card-header .mat-card-title {
  font-size: 25px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-checkbox {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-calendar {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 500;
}

.mat-dialog-title {
  font: 600 25px / 32px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 500 16px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.25;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.4375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.25;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.25em;
  line-height: 1.25;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.9375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.4375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.43749em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.9375em;
  padding-top: 0.9375em;
}

.mat-form-field-label {
  top: 1.4375em;
}

.mat-form-field-underline {
  bottom: 1.4375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.9166666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.3125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.31249em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.31248em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.3125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.4166666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31247em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31246em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31245em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.1875em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.6875em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.68749em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.9375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.6875em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.68749em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.125em;
}

.mat-menu-item {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
}

.mat-radio-button {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-select {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-select-trigger {
  height: 1.25em;
}

.mat-slide-toggle-content {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-slider-thumb-label-text {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-step-label {
  font-size: 16px;
  font-weight: 500;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-tab-label, .mat-tab-link {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 600 25px / 32px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-list-option {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 500;
  font-size: 16px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 700 32px / 48px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 600 25px / 32px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 500 calc(16px * 0.83) / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 500 calc(16px * 0.67) / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 500 16px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 96px / 96px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 0em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 300 56px / 56px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 300 45px / 48px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 300 34px / 40px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 500 16px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-card {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-card-title {
  font-size: 32px;
  font-weight: 600;
}

.mat-card-header .mat-card-title {
  font-size: 25px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-checkbox {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-calendar {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 500;
}

.mat-dialog-title {
  font: 600 25px / 32px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 500 16px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.25;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.4375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.25;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.25em;
  line-height: 1.25;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.9375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.43748em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.43747em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.9375em;
  padding-top: 0.9375em;
}

.mat-form-field-label {
  top: 1.4375em;
}

.mat-form-field-underline {
  bottom: 1.4375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.9166666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  -ms-transform: translateY(-1.31244em) scale(0.75);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  -ms-transform: translateY(-1.31243em) scale(0.75);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  -ms-transform: translateY(-1.31242em) scale(0.75);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.3125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.4166666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31241em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.3124em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31239em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.1875em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.68748em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.68747em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.9375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.68748em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.68747em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.125em;
}

.mat-menu-item {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
}

.mat-radio-button {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-select {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-select-trigger {
  height: 1.25em;
}

.mat-slide-toggle-content {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-slider-thumb-label-text {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-step-label {
  font-size: 16px;
  font-weight: 500;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-tab-label, .mat-tab-link {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 600 25px / 32px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-list-option {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 500;
  font-size: 16px;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #7b1fa2;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f57c00;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #7b1fa2;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f57c00;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #7b1fa2;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #f57c00;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #7b1fa2;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #f57c00;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #7b1fa2;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #f57c00;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #7b1fa2;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #f57c00;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #7b1fa2;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f57c00;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #7b1fa2;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #f57c00;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #7b1fa2;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #f57c00;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(123, 31, 162, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(123, 31, 162, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(123, 31, 162, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #7b1fa2;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(123, 31, 162, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(123, 31, 162, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(245, 124, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(245, 124, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(245, 124, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #f57c00;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(245, 124, 0, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(245, 124, 0, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #7b1fa2;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #f57c00;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #7b1fa2;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #f57c00;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #f57c00;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #7b1fa2;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #f57c00;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #7b1fa2;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #f57c00;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #7b1fa2;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #f57c00;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #7b1fa2;
}
.mat-icon.mat-accent {
  color: #f57c00;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #7b1fa2;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #f57c00;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #dac3e4;
}

.mat-progress-bar-buffer {
  background-color: #dac3e4;
}

.mat-progress-bar-fill::after {
  background-color: #7b1fa2;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f9dbbc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f9dbbc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #f57c00;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #7b1fa2;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #f57c00;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #7b1fa2;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #7b1fa2;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f57c00;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f57c00;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #7b1fa2;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #f57c00;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f57c00;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(245, 124, 0, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #f57c00;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #7b1fa2;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(123, 31, 162, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #7b1fa2;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #7b1fa2;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(123, 31, 162, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #f57c00;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(245, 124, 0, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #7b1fa2;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #f57c00;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #7b1fa2;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 128, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #f57c00;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #7b1fa2;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 128, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #f57c00;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #7b1fa2;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #f57c00;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #f57c00;
}

/*
According to the specification (https://www.w3.org/TR/css-scoping-1/#host-selector)
:host and :host-context are pseudo-classes. So we assume they could be combined,
like other pseudo-classes, even same ones.
For example: ':nth-of-type(2n):nth-of-type(even)'.

Ideal solution would be to prepend any selector with :host-context([dir=rtl]).
Then nebular components will behave as an html element and respond to [dir] attribute on any level,
so direction could be overridden on any component level.

Implementation code:

@mixin app-rtl() {
  // add # to scss interpolation statement.
  // it works in comments and we can't use it here
  @at-root {selector-append(':host-context([dir=rtl])', &)} {
    @content;
  }
}

And when we call it somewhere:

:host {
  .some-class {
    @include app-rtl() {
      ...
    }
  }
}
:host-context(...) {
  .some-class {
    @include app-rtl() {
      ...
    }
  }
}

Result will look like:

:host-context([dir=rtl]):host .some-class {
  ...
}
:host-context([dir=rtl]):host-context(...) .some-class {
  ...
}

*
  Side note:
  :host-context():host selector are valid. https://lists.w3.org/Archives/Public/www-style/2015Feb/0305.html

  :host-context([dir=rtl]):host-context(...) should match any permutation,
  so order is not important.
*


Currently, there're two problems with this approach:

First, is that we can't combine :host, :host-context. Angular bugs #14349, #19199.
For the moment of writing, the only possible way is:
:host {
  :host-context(...) {
    ...
  }
}
It doesn't work for us because mixin could be called somewhere deeper, like:
:host {
  p {
    @include app-rtl() { ... }
  }
}
We are not able to go up to :host level to place content passed to mixin.

The second problem is that we only can be sure that we appending :host-context([dir=rtl]) to another
:host/:host-context pseudo-class when called in theme files (*.theme.scss).
  *
    Side note:
    Currently, app-install-component uses another approach where :host prepended with the theme name
    (https://github.com/angular/angular/blob/5b96078624b0a4760f2dbcf6fdf0bd62791be5bb/packages/compiler/src/shadow_css.ts#L441),
    but it was made to be able to use current realization of rtl and it can be rewritten back to
    :host-context($theme) once we will be able to use multiple shadow selectors.
  *
But when it's called in *.component.scss we can't be sure, that selector starts with :host/:host-context,
because angular allows omitting pseudo-classes if we don't need to style :host component itself.
We can break such selectors, by just appending :host-context([dir=rtl]) to them.
  ***
    Possible solution
    check if we in theme by some theme variables and if so append, otherwise nest like
    @at-root :host-context([dir=rtl]) {
      // add # to scss interpolation statement.
      // it works in comments and we can't use it here
      {&} {
        @content;
      }
    }
    What if :host specified? Can we add space in :host-context(...) :host?
    Or maybe add :host selector anyway? If multiple :host selectors are allowed
  ***


Problems with the current approach.

1. Direction can be applied only on document level, because mixin prepends theme class,
which placed on the body.
2. *.component.scss styles should be in :host selector. Otherwise angular will add host
attribute to [dir=rtl] attribute as well.


General problems.

Ltr is default document direction, but for proper work of app-ltr (means ltr only),
[dir=ltr] should be specified at least somewhere. ':not([dir=rtl]' not applicable here,
because it's satisfy any parent, that don't have [dir=rtl] attribute.
Previous approach was to use single rtl mixin and reset ltr properties to initial value.
But sometimes it's hard to find, what the previous value should be. And such mixin call looks too verbose.
*/
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #212121;
  background: #fff;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  line-height: normal;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-size: 0.875em;
  /* 2 */
  color: #ff1744;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

div,
pre {
  white-space: pre-wrap;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

mark {
  background-color: transparent;
  font-weight: 500;
  border-bottom: solid 4px #fff176;
}
mark:hover {
  border-bottom: solid 6px #ffeb3b;
}

dfn {
  font-style: normal;
}

a {
  white-space: pre-wrap;
  color: #2979ff;
  text-decoration: none;
}

dl,
ol,
ul {
  margin-bottom: 1rem;
}

.ms-branding {
  display: inline-block;
  color: inherit;
  font-size: 16px;
  white-space: nowrap;
}

.ms-branding-logo-expanded {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
}
[dir=rtl] .ms-branding-logo-expanded {
  margin-left: 10px;
  margin-right: auto;
}

.ms-branding-name {
  font-weight: bold;
  font-size: 1.1rem;
  vertical-align: middle;
}

.ms-header {
  position: relative;
  z-index: 201;
  display: block;
}
.ms-header .ms-toolbar {
  height: 64px;
  padding: 0 8px;
}
.ms-header-fixed .ms-header {
  position: sticky;
  top: 0;
}

.ms-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.ms-navbar-side.ms-header-fixed .ms-toolbar {
  border-bottom: unset;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ms-toolbar .badge {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 12px;
  border-radius: 10px;
}

.ms-header-white .ms-toolbar,
.ms-header-white .ms-menubar {
  background-color: white;
}

.ms-toolbar-button.mat-button, .ms-toolbar-button.mat-icon-button {
  margin: 0 4px;
}
.ms-toolbar-button.mat-button {
  min-width: unset;
  line-height: 40px;
  border-radius: 999px;
}
.ms-toolbar-button.ms-avatar-button {
  display: block;
  padding: 0 4px;
  font-size: 0;
}
.ms-toolbar-button.ms-avatar-button .ms-username {
  margin: 0 8px;
  font-size: 14px;
}

.mat-icon {
  text-align: center;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  min-height: 1em;
}

.ms-svg-icon {
  display: inline-block;
  text-align: center;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  min-height: 1em;
  line-height: 1em;
  font-size: inherit;
  vertical-align: -0.125em;
}

.m-main {
  margin: 16px;
}
@media (max-width: 959px) {
  .m-main {
    margin: 0px;
  }
}

.ms-container-wrap,
.ms-container {
  height: 100%;
}

.ms-content {
  position: relative;
  padding: 16px;
}

.ms-row {
  margin-right: -8px;
  margin-left: -8px;
}

.ms-col {
  padding-right: 8px;
  padding-left: 8px;
}

.ms-header-above .ms-container {
  height: calc(100% - 64px) !important;
}
.ms-header-above .ms-sidebar-main {
  height: 100% !important;
}

.ms-content-width-fix .ms-content-wrap {
  margin-left: 240px !important;
}
[dir=rtl] .ms-content-width-fix .ms-content-wrap {
  margin-left: auto !important;
  margin-right: 240px !important;
}

.ms-sidenav {
  position: absolute;
  overflow-x: hidden;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ms-sidenav.mat-drawer-side {
  border-width: 0;
}
.ms-sidenav .ms-sidemenu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ms-sidenav .ms-sidemenu .ms-sidemenu-item {
  display: block;
  height: auto;
  padding: 0;
}
.ms-sidenav .ms-sidemenu .ms-sidemenu-item.open > .ms-sidemenu {
  max-height: 2000px;
}
.ms-sidenav .ms-sidemenu .ms-sidemenu-item.open > .ms-sidemenu-toggle > .menu-caret {
  transform: rotate(-180deg);
}
.ms-sidenav .ms-sidemenu a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  text-decoration: none;
}
.ms-sidenav .ms-sidemenu .menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 16px;
  font-size: 18px;
  line-height: 18px;
}
[dir=rtl] .ms-sidenav .ms-sidemenu .menu-icon {
  margin-left: 16px;
  margin-right: auto;
}
.ms-sidenav .ms-sidemenu .menu-name,
.ms-sidenav .ms-sidemenu .menu-label,
.ms-sidenav .ms-sidemenu .menu-badge {
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ms-sidenav .ms-sidemenu .menu-label,
.ms-sidenav .ms-sidemenu .menu-badge {
  display: inline-block;
  min-width: 18px;
  padding: 3px 5px;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #757575;
  border-radius: 2px;
}
.ms-sidenav .ms-sidemenu .menu-badge {
  border-radius: 10px;
}
.ms-sidenav .ms-sidemenu .menu-caret {
  display: block;
  text-align: center;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1);
}
.ms-sidenav .ms-sidemenu.level-0 > li > a > .menu-name {
  margin-right: 5px;
}
[dir=rtl] .ms-sidenav .ms-sidemenu.level-0 > li > a > .menu-name {
  margin-left: 5px;
  margin-right: auto;
}
.ms-sidenav .ms-sidemenu.level-1 > li > a {
  padding-left: 50px;
}
[dir=rtl] .ms-sidenav .ms-sidemenu.level-1 > li > a {
  padding-left: 16px;
  padding-right: 50px;
}
.ms-sidenav .ms-sidemenu.level-2 > li > a {
  padding-left: 64px;
}
[dir=rtl] .ms-sidenav .ms-sidemenu.level-2 > li > a {
  padding-left: 16px;
  padding-right: 64px;
}
.ms-sidenav .ms-sidemenu.submenu {
  max-height: 0;
  padding-top: 0;
  overflow: hidden;
  transform: translateZ(0) !important;
  transition: max-height 225ms cubic-bezier(0.4, 0, 0.2, 1);
}
.ms-sidenav .ms-sidemenu.submenu .ms-sidemenu-item {
  border-left-width: 0;
}
.ms-sidenav .ms-sidebar-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 10px;
  overflow: hidden;
}
@media (max-width: 599px) {
  .ms-sidenav .ms-sidebar-header {
    height: 56px;
  }
}
.ms-header-white .ms-sidenav .ms-sidebar-header {
  background-color: white;
}
.ms-sidenav .ms-sidebar-main {
  height: calc(100% - 64px);
  overflow: auto;
}
@media (max-width: 599px) {
  .ms-sidenav .ms-sidebar-main {
    height: calc(100% - 56px);
  }
}

.ms-user-panel {
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ms-user-panel-avatar {
  margin-bottom: 8px;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ms-user-panel-name,
.ms-user-panel-email {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: normal;
}

.ms-user-panel-name,
.ms-user-panel-email,
.ms-user-panel-icons {
  opacity: 1;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ms-user-panel-icons {
  white-space: nowrap;
}
.ms-user-panel-icons .mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ms-user-panel-icons .mat-icon-button .mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}

.ms-sidenav-collapsed .ms-sidenav,
.ms-sidenav-collapsed-fix .ms-sidenav {
  width: 50px;
}
.ms-sidenav-collapsed .ms-sidenav .menu-name,
.ms-sidenav-collapsed .ms-sidenav .menu-label,
.ms-sidenav-collapsed .ms-sidenav .menu-badge,
.ms-sidenav-collapsed .ms-sidenav .menu-caret,
.ms-sidenav-collapsed .ms-sidenav .ms-user-panel-name,
.ms-sidenav-collapsed .ms-sidenav .ms-user-panel-email,
.ms-sidenav-collapsed .ms-sidenav .ms-user-panel-icons,
.ms-sidenav-collapsed-fix .ms-sidenav .menu-name,
.ms-sidenav-collapsed-fix .ms-sidenav .menu-label,
.ms-sidenav-collapsed-fix .ms-sidenav .menu-badge,
.ms-sidenav-collapsed-fix .ms-sidenav .menu-caret,
.ms-sidenav-collapsed-fix .ms-sidenav .ms-user-panel-name,
.ms-sidenav-collapsed-fix .ms-sidenav .ms-user-panel-email,
.ms-sidenav-collapsed-fix .ms-sidenav .ms-user-panel-icons {
  opacity: 0;
}
.ms-sidenav-collapsed .ms-sidenav .ms-user-panel-avatar,
.ms-sidenav-collapsed-fix .ms-sidenav .ms-user-panel-avatar {
  transform: scale(0.5);
}
.ms-sidenav-collapsed .ms-sidenav:hover,
.ms-sidenav-collapsed-fix .ms-sidenav:hover {
  width: 240px;
}
.ms-sidenav-collapsed .ms-sidenav:hover .menu-name,
.ms-sidenav-collapsed .ms-sidenav:hover .menu-label,
.ms-sidenav-collapsed .ms-sidenav:hover .menu-badge,
.ms-sidenav-collapsed .ms-sidenav:hover .menu-caret,
.ms-sidenav-collapsed .ms-sidenav:hover .ms-user-panel-name,
.ms-sidenav-collapsed .ms-sidenav:hover .ms-user-panel-email,
.ms-sidenav-collapsed .ms-sidenav:hover .ms-user-panel-icons,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .menu-name,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .menu-label,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .menu-badge,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .menu-caret,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .ms-user-panel-name,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .ms-user-panel-email,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .ms-user-panel-icons {
  opacity: 1;
}
.ms-sidenav-collapsed .ms-sidenav:hover .ms-user-panel-avatar,
.ms-sidenav-collapsed-fix .ms-sidenav:hover .ms-user-panel-avatar {
  transform: scale(1);
}

.ms-sidenav-collapsed .ms-content-wrap {
  margin-left: 50px !important;
}
[dir=rtl] .ms-sidenav-collapsed .ms-content-wrap {
  margin-left: auto !important;
  margin-right: 50px !important;
}
.ms-sidenav-collapsed[dir=rtl] .ms-content-wrap {
  margin-left: auto !important;
  margin-right: 50px !important;
}

.ms-menubar {
  position: sticky;
  z-index: 200;
  padding: 8px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.ms-menubar .mat-icon {
  margin-top: -2px;
}
.ms-menubar .menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  font-size: 18px;
  line-height: 18px;
}
[dir=rtl] .ms-menubar .menu-icon {
  margin-left: 8px;
  margin-right: auto;
}
.ms-menubar .menu-label,
.ms-menubar .menu-badge {
  margin-left: 8px;
  font-size: 12px;
}
[dir=rtl] .ms-menubar .menu-label,
[dir=rtl] .ms-menubar .menu-badge {
  margin-left: auto;
  margin-right: 8px;
}
.ms-menubar .menu-badge {
  border-radius: 10px;
}
.ms-menubar .menu-caret {
  margin-right: -8px;
}
[dir=rtl] .ms-menubar .menu-caret {
  margin-left: -8px;
  margin-right: auto;
}
.ms-menubar .mat-tab-nav-bar,
.ms-menubar .mat-tab-header {
  border-bottom: none;
}

.mat-menu-item .menu-name {
  margin-right: 8px;
  vertical-align: middle;
}
[dir=rtl] .mat-menu-item .menu-name {
  margin-left: 8px;
  margin-right: auto;
}

.mat-tab-links {
  justify-content: center;
}

.ms-navbar-top .ms-menubar {
  top: 0;
}
.ms-navbar-top .ms-branding {
  margin-left: 16px;
}
[dir=rtl] .ms-navbar-top .ms-branding {
  margin-left: auto;
  margin-right: 16px;
}

.ms-header-fixed .ms-menubar {
  top: 64px;
}
@media (max-width: 599px) {
  .ms-header-fixed .ms-menubar {
    top: 56px;
  }
}

.mat-nav-list > a.active {
  color: #f57c00;
}

.button, .btn-post, .secondary-btn, .primary-btn {
  padding: 17px 35px;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border: 0;
}
.button:hover, .btn-post:hover, .secondary-btn:hover, .primary-btn:hover, .button:focus, .btn-post:focus, .secondary-btn:focus, .primary-btn:focus {
  border: 0;
}

.primary-btn {
  padding: 7px 25px;
  background-color: #a836bc;
  box-shadow: 1px 0 10px -1px rgba(124, 16, 106, 0.7);
}
.primary-btn:hover {
  background-color: #36063d;
}
@media (max-width: 767px) {
  .primary-btn {
    padding: 7px 25px;
  }
}
@media (min-width: 992px) {
  .primary-btn {
    padding: 12px 30px;
  }
}
@media (min-width: 1200px) {
  .primary-btn {
    padding: 17px 35px;
  }
}

.secondary-btn {
  padding: 7px 25px;
  background-color: #f47321;
  box-shadow: 1px 0 10px -1px rgba(228, 84, 71, 0.7);
}
.secondary-btn:hover {
  background-color: #e65a00;
}
@media (max-width: 767px) {
  .secondary-btn {
    padding: 7px 25px;
  }
}
@media (min-width: 992px) {
  .secondary-btn {
    padding: 12px 30px;
  }
}
@media (min-width: 1200px) {
  .secondary-btn {
    padding: 17px 35px;
  }
}

.btn-post {
  padding: 17px 22px;
  background: #36003d;
  background: linear-gradient(40deg, rgba(59, 1, 66, 0.95) 8%, rgba(86, 0, 97, 0.95) 17%, rgba(164, 52, 184, 0.95) 95%);
  box-shadow: 1px 0 10px -1px rgba(164, 52, 184, 0.7);
}
.btn-post:hover {
  box-shadow: in 1px 0px 10px -1px black;
  background-color: #36063d;
}

.avatar-r-50 {
  display: inline-block;
  height: 50px;
  width: 50px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.avatar-r-45 {
  display: inline-block;
  height: 45px;
  width: 45px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.avatar-r-40 {
  display: inline-block;
  height: 40px;
  width: 40px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.avatar-r-35 {
  display: inline-block;
  height: 32px;
  width: 32px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.avatar-70 {
  display: inline-block;
  height: 70px;
  width: 70px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.avatar-50 {
  display: inline-block;
  height: 50px;
  width: 50px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.avatar-45 {
  display: inline-block;
  height: 45px;
  width: 45px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.avatar-40 {
  display: inline-block;
  height: 40px;
  width: 40px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.avatar-35 {
  display: inline-block;
  height: 32px;
  width: 32px;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  vertical-align: middle;
  flex-shrink: 0;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.875rem;
  color: white;
  background-color: #f7f7fc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.badge {
  display: inline-block;
  min-width: 18px;
  padding: 3px 5px;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #757575;
  border-radius: 2px;
}

.badge-r {
  background: #882898;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track, *::-webkit-scrollbar-thumb {
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
}
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px inset;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 999px;
  min-height: 20px;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollbar-thin::-webkit-scrollbar-track, .scrollbar-thin::-webkit-scrollbar-thumb {
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
}
.scrollbar-thin::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px inset;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 999px;
  min-height: 20px;
}
.scrollbar-thin::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ms-sidenav .ms-sidemenu a {
  color: rgba(0, 0, 0, 0.87);
}
.ms-sidenav .ms-sidemenu .ms-sidemenu-item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ms-sidenav .ms-sidemenu .ms-sidemenu-item.open {
  background: rgba(0, 0, 0, 0.02);
}
.ms-sidenav .ms-sidebar-header {
  color: rgba(0, 0, 0, 0.87);
  background: whitesmoke;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ms-menubar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.ms-menubar .mat-button.active {
  background-color: rgba(0, 0, 0, 0.12);
}

.ms-toolbar-button.mat-icon-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/*
.theme-dark {
  $primary: mat.define-palette(mat.$blue-palette);
  $accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
  $warn: mat.define-palette(mat.$deep-orange-palette);
  $theme: mat.define-dark-theme($primary, $accent, $warn);

  @include mat.all-component-themes($theme);
  @include ms-app-theme($theme);
}
*/
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1920px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-xl-12 {
    margin-left: 100%;
  }
}
.text-red-50 {
  color: #ffebee !important;
}

.text-red-100 {
  color: #ffcdd2 !important;
}

.text-red-200 {
  color: #ef9a9a !important;
}

.text-red-300 {
  color: #e57373 !important;
}

.text-red-400 {
  color: #ef5350 !important;
}

.text-red-500 {
  color: #f44336 !important;
}

.text-red-600 {
  color: #e53935 !important;
}

.text-red-700 {
  color: #d32f2f !important;
}

.text-red-800 {
  color: #c62828 !important;
}

.text-red-900 {
  color: #b71c1c !important;
}

.text-red-A100 {
  color: #ff8a80 !important;
}

.text-red-A200 {
  color: #ff5252 !important;
}

.text-red-A400 {
  color: #ff1744 !important;
}

.text-red-A700 {
  color: #d50000 !important;
}

.text-pink-50 {
  color: #fce4ec !important;
}

.text-pink-100 {
  color: #f8bbd0 !important;
}

.text-pink-200 {
  color: #f48fb1 !important;
}

.text-pink-300 {
  color: #f06292 !important;
}

.text-pink-400 {
  color: #ec407a !important;
}

.text-pink-500 {
  color: #e91e63 !important;
}

.text-pink-600 {
  color: #d81b60 !important;
}

.text-pink-700 {
  color: #c2185b !important;
}

.text-pink-800 {
  color: #ad1457 !important;
}

.text-pink-900 {
  color: #880e4f !important;
}

.text-pink-A100 {
  color: #ff80ab !important;
}

.text-pink-A200 {
  color: #ff4081 !important;
}

.text-pink-A400 {
  color: #f50057 !important;
}

.text-pink-A700 {
  color: #c51162 !important;
}

.text-purple-50 {
  color: #f3e5f5 !important;
}

.text-purple-100 {
  color: #e1bee7 !important;
}

.text-purple-200 {
  color: #ce93d8 !important;
}

.text-purple-300 {
  color: #ba68c8 !important;
}

.text-purple-400 {
  color: #ab47bc !important;
}

.text-purple-500 {
  color: #9c27b0 !important;
}

.text-purple-600 {
  color: #8e24aa !important;
}

.text-purple-700 {
  color: #7b1fa2 !important;
}

.text-purple-800 {
  color: #6a1b9a !important;
}

.text-purple-900 {
  color: #4a148c !important;
}

.text-purple-A100 {
  color: #ea80fc !important;
}

.text-purple-A200 {
  color: #e040fb !important;
}

.text-purple-A400 {
  color: #d500f9 !important;
}

.text-purple-A700 {
  color: #aa00ff !important;
}

.text-deep-purple-50 {
  color: #ede7f6 !important;
}

.text-deep-purple-100 {
  color: #d1c4e9 !important;
}

.text-deep-purple-200 {
  color: #b39ddb !important;
}

.text-deep-purple-300 {
  color: #9575cd !important;
}

.text-deep-purple-400 {
  color: #7e57c2 !important;
}

.text-deep-purple-500 {
  color: #673ab7 !important;
}

.text-deep-purple-600 {
  color: #5e35b1 !important;
}

.text-deep-purple-700 {
  color: #512da8 !important;
}

.text-deep-purple-800 {
  color: #4527a0 !important;
}

.text-deep-purple-900 {
  color: #311b92 !important;
}

.text-deep-purple-A100 {
  color: #b388ff !important;
}

.text-deep-purple-A200 {
  color: #7c4dff !important;
}

.text-deep-purple-A400 {
  color: #651fff !important;
}

.text-deep-purple-A700 {
  color: #6200ea !important;
}

.text-indigo-50 {
  color: #e8eaf6 !important;
}

.text-indigo-100 {
  color: #c5cae9 !important;
}

.text-indigo-200 {
  color: #9fa8da !important;
}

.text-indigo-300 {
  color: #7986cb !important;
}

.text-indigo-400 {
  color: #5c6bc0 !important;
}

.text-indigo-500 {
  color: #3f51b5 !important;
}

.text-indigo-600 {
  color: #3949ab !important;
}

.text-indigo-700 {
  color: #303f9f !important;
}

.text-indigo-800 {
  color: #283593 !important;
}

.text-indigo-900 {
  color: #1a237e !important;
}

.text-indigo-A100 {
  color: #8c9eff !important;
}

.text-indigo-A200 {
  color: #536dfe !important;
}

.text-indigo-A400 {
  color: #3d5afe !important;
}

.text-indigo-A700 {
  color: #304ffe !important;
}

.text-blue-50 {
  color: #e3f2fd !important;
}

.text-blue-100 {
  color: #bbdefb !important;
}

.text-blue-200 {
  color: #90caf9 !important;
}

.text-blue-300 {
  color: #64b5f6 !important;
}

.text-blue-400 {
  color: #42a5f5 !important;
}

.text-blue-500 {
  color: #2196f3 !important;
}

.text-blue-600 {
  color: #1e88e5 !important;
}

.text-blue-700 {
  color: #1976d2 !important;
}

.text-blue-800 {
  color: #1565c0 !important;
}

.text-blue-900 {
  color: #0d47a1 !important;
}

.text-blue-A100 {
  color: #82b1ff !important;
}

.text-blue-A200 {
  color: #448aff !important;
}

.text-blue-A400 {
  color: #2979ff !important;
}

.text-blue-A700 {
  color: #2962ff !important;
}

.text-light-blue-50 {
  color: #e1f5fe !important;
}

.text-light-blue-100 {
  color: #b3e5fc !important;
}

.text-light-blue-200 {
  color: #81d4fa !important;
}

.text-light-blue-300 {
  color: #4fc3f7 !important;
}

.text-light-blue-400 {
  color: #29b6f6 !important;
}

.text-light-blue-500 {
  color: #03a9f4 !important;
}

.text-light-blue-600 {
  color: #039be5 !important;
}

.text-light-blue-700 {
  color: #0288d1 !important;
}

.text-light-blue-800 {
  color: #0277bd !important;
}

.text-light-blue-900 {
  color: #01579b !important;
}

.text-light-blue-A100 {
  color: #80d8ff !important;
}

.text-light-blue-A200 {
  color: #40c4ff !important;
}

.text-light-blue-A400 {
  color: #00b0ff !important;
}

.text-light-blue-A700 {
  color: #0091ea !important;
}

.text-cyan-50 {
  color: #e0f7fa !important;
}

.text-cyan-100 {
  color: #b2ebf2 !important;
}

.text-cyan-200 {
  color: #80deea !important;
}

.text-cyan-300 {
  color: #4dd0e1 !important;
}

.text-cyan-400 {
  color: #26c6da !important;
}

.text-cyan-500 {
  color: #00bcd4 !important;
}

.text-cyan-600 {
  color: #00acc1 !important;
}

.text-cyan-700 {
  color: #0097a7 !important;
}

.text-cyan-800 {
  color: #00838f !important;
}

.text-cyan-900 {
  color: #006064 !important;
}

.text-cyan-A100 {
  color: #84ffff !important;
}

.text-cyan-A200 {
  color: #18ffff !important;
}

.text-cyan-A400 {
  color: #00e5ff !important;
}

.text-cyan-A700 {
  color: #00b8d4 !important;
}

.text-teal-50 {
  color: #e0f2f1 !important;
}

.text-teal-100 {
  color: #b2dfdb !important;
}

.text-teal-200 {
  color: #80cbc4 !important;
}

.text-teal-300 {
  color: #4db6ac !important;
}

.text-teal-400 {
  color: #26a69a !important;
}

.text-teal-500 {
  color: #009688 !important;
}

.text-teal-600 {
  color: #00897b !important;
}

.text-teal-700 {
  color: #00796b !important;
}

.text-teal-800 {
  color: #00695c !important;
}

.text-teal-900 {
  color: #004d40 !important;
}

.text-teal-A100 {
  color: #a7ffeb !important;
}

.text-teal-A200 {
  color: #64ffda !important;
}

.text-teal-A400 {
  color: #1de9b6 !important;
}

.text-teal-A700 {
  color: #00bfa5 !important;
}

.text-green-50 {
  color: #e8f5e9 !important;
}

.text-green-100 {
  color: #c8e6c9 !important;
}

.text-green-200 {
  color: #a5d6a7 !important;
}

.text-green-300 {
  color: #81c784 !important;
}

.text-green-400 {
  color: #66bb6a !important;
}

.text-green-500 {
  color: #4caf50 !important;
}

.text-green-600 {
  color: #43a047 !important;
}

.text-green-700 {
  color: #388e3c !important;
}

.text-green-800 {
  color: #2e7d32 !important;
}

.text-green-900 {
  color: #1b5e20 !important;
}

.text-green-A100 {
  color: #b9f6ca !important;
}

.text-green-A200 {
  color: #69f0ae !important;
}

.text-green-A400 {
  color: #00e676 !important;
}

.text-green-A700 {
  color: #00c853 !important;
}

.text-light-green-50 {
  color: #f1f8e9 !important;
}

.text-light-green-100 {
  color: #dcedc8 !important;
}

.text-light-green-200 {
  color: #c5e1a5 !important;
}

.text-light-green-300 {
  color: #aed581 !important;
}

.text-light-green-400 {
  color: #9ccc65 !important;
}

.text-light-green-500 {
  color: #8bc34a !important;
}

.text-light-green-600 {
  color: #7cb342 !important;
}

.text-light-green-700 {
  color: #689f38 !important;
}

.text-light-green-800 {
  color: #558b2f !important;
}

.text-light-green-900 {
  color: #33691e !important;
}

.text-light-green-A100 {
  color: #ccff90 !important;
}

.text-light-green-A200 {
  color: #b2ff59 !important;
}

.text-light-green-A400 {
  color: #76ff03 !important;
}

.text-light-green-A700 {
  color: #64dd17 !important;
}

.text-lime-50 {
  color: #f9fbe7 !important;
}

.text-lime-100 {
  color: #f0f4c3 !important;
}

.text-lime-200 {
  color: #e6ee9c !important;
}

.text-lime-300 {
  color: #dce775 !important;
}

.text-lime-400 {
  color: #d4e157 !important;
}

.text-lime-500 {
  color: #cddc39 !important;
}

.text-lime-600 {
  color: #c0ca33 !important;
}

.text-lime-700 {
  color: #afb42b !important;
}

.text-lime-800 {
  color: #9e9d24 !important;
}

.text-lime-900 {
  color: #827717 !important;
}

.text-lime-A100 {
  color: #f4ff81 !important;
}

.text-lime-A200 {
  color: #eeff41 !important;
}

.text-lime-A400 {
  color: #c6ff00 !important;
}

.text-lime-A700 {
  color: #aeea00 !important;
}

.text-yellow-50 {
  color: #fffde7 !important;
}

.text-yellow-100 {
  color: #fff9c4 !important;
}

.text-yellow-200 {
  color: #fff59d !important;
}

.text-yellow-300 {
  color: #fff176 !important;
}

.text-yellow-400 {
  color: #ffee58 !important;
}

.text-yellow-500 {
  color: #ffeb3b !important;
}

.text-yellow-600 {
  color: #fdd835 !important;
}

.text-yellow-700 {
  color: #fbc02d !important;
}

.text-yellow-800 {
  color: #f9a825 !important;
}

.text-yellow-900 {
  color: #f57f17 !important;
}

.text-yellow-A100 {
  color: #ffff8d !important;
}

.text-yellow-A200 {
  color: #ffff00 !important;
}

.text-yellow-A400 {
  color: #ffea00 !important;
}

.text-yellow-A700 {
  color: #ffd600 !important;
}

.text-amber-50 {
  color: #fff8e1 !important;
}

.text-amber-100 {
  color: #ffecb3 !important;
}

.text-amber-200 {
  color: #ffe082 !important;
}

.text-amber-300 {
  color: #ffd54f !important;
}

.text-amber-400 {
  color: #ffca28 !important;
}

.text-amber-500 {
  color: #ffc107 !important;
}

.text-amber-600 {
  color: #ffb300 !important;
}

.text-amber-700 {
  color: #ffa000 !important;
}

.text-amber-800 {
  color: #ff8f00 !important;
}

.text-amber-900 {
  color: #ff6f00 !important;
}

.text-amber-A100 {
  color: #ffe57f !important;
}

.text-amber-A200 {
  color: #ffd740 !important;
}

.text-amber-A400 {
  color: #ffc400 !important;
}

.text-amber-A700 {
  color: #ffab00 !important;
}

.text-orange-50 {
  color: #fff3e0 !important;
}

.text-orange-100 {
  color: #ffe0b2 !important;
}

.text-orange-200 {
  color: #ffcc80 !important;
}

.text-orange-300 {
  color: #ffb74d !important;
}

.text-orange-400 {
  color: #ffa726 !important;
}

.text-orange-500 {
  color: #ff9800 !important;
}

.text-orange-600 {
  color: #fb8c00 !important;
}

.text-orange-700 {
  color: #f57c00 !important;
}

.text-orange-800 {
  color: #ef6c00 !important;
}

.text-orange-900 {
  color: #e65100 !important;
}

.text-orange-A100 {
  color: #ffd180 !important;
}

.text-orange-A200 {
  color: #ffab40 !important;
}

.text-orange-A400 {
  color: #ff9100 !important;
}

.text-orange-A700 {
  color: #ff6d00 !important;
}

.text-deep-orange-50 {
  color: #fbe9e7 !important;
}

.text-deep-orange-100 {
  color: #ffccbc !important;
}

.text-deep-orange-200 {
  color: #ffab91 !important;
}

.text-deep-orange-300 {
  color: #ff8a65 !important;
}

.text-deep-orange-400 {
  color: #ff7043 !important;
}

.text-deep-orange-500 {
  color: #ff5722 !important;
}

.text-deep-orange-600 {
  color: #f4511e !important;
}

.text-deep-orange-700 {
  color: #e64a19 !important;
}

.text-deep-orange-800 {
  color: #d84315 !important;
}

.text-deep-orange-900 {
  color: #bf360c !important;
}

.text-deep-orange-A100 {
  color: #ff9e80 !important;
}

.text-deep-orange-A200 {
  color: #ff6e40 !important;
}

.text-deep-orange-A400 {
  color: #ff3d00 !important;
}

.text-deep-orange-A700 {
  color: #dd2c00 !important;
}

.text-brown-50 {
  color: #efebe9 !important;
}

.text-brown-100 {
  color: #d7ccc8 !important;
}

.text-brown-200 {
  color: #bcaaa4 !important;
}

.text-brown-300 {
  color: #a1887f !important;
}

.text-brown-400 {
  color: #8d6e63 !important;
}

.text-brown-500 {
  color: #795548 !important;
}

.text-brown-600 {
  color: #6d4c41 !important;
}

.text-brown-700 {
  color: #5d4037 !important;
}

.text-brown-800 {
  color: #4e342e !important;
}

.text-brown-900 {
  color: #3e2723 !important;
}

.text-brown-A100 {
  color: #d7ccc8 !important;
}

.text-brown-A200 {
  color: #bcaaa4 !important;
}

.text-brown-A400 {
  color: #8d6e63 !important;
}

.text-brown-A700 {
  color: #5d4037 !important;
}

.text-gray-50 {
  color: #fafafa !important;
}

.text-gray-100 {
  color: #f5f5f5 !important;
}

.text-gray-200 {
  color: #eeeeee !important;
}

.text-gray-300 {
  color: #e0e0e0 !important;
}

.text-gray-400 {
  color: #bdbdbd !important;
}

.text-gray-500 {
  color: #9e9e9e !important;
}

.text-gray-600 {
  color: #757575 !important;
}

.text-gray-700 {
  color: #616161 !important;
}

.text-gray-800 {
  color: #424242 !important;
}

.text-gray-900 {
  color: #212121 !important;
}

.text-gray-A100 {
  color: #ffffff !important;
}

.text-gray-A200 {
  color: #eeeeee !important;
}

.text-gray-A400 {
  color: #bdbdbd !important;
}

.text-gray-A700 {
  color: #616161 !important;
}

.text-blue-gray-50, .text-blue-grey-50 {
  color: #eceff1 !important;
}

.text-blue-gray-100, .text-blue-grey-100 {
  color: #cfd8dc !important;
}

.text-blue-gray-200, .text-blue-grey-200 {
  color: #b0bec5 !important;
}

.text-blue-gray-300, .text-blue-grey-300 {
  color: #90a4ae !important;
}

.text-blue-gray-400, .text-blue-grey-400 {
  color: #78909c !important;
}

.text-blue-gray-500, .text-blue-grey-500 {
  color: #607d8b !important;
}

.text-blue-gray-600, .text-blue-grey-600 {
  color: #546e7a !important;
}

.text-blue-gray-700, .text-blue-grey-700 {
  color: #455a64 !important;
}

.text-blue-gray-800, .text-blue-grey-800 {
  color: #37474f !important;
}

.text-blue-gray-900, .text-blue-grey-900 {
  color: #263238 !important;
}

.text-blue-gray-A100, .text-blue-grey-A100 {
  color: #cfd8dc !important;
}

.text-blue-gray-A200, .text-blue-grey-A200 {
  color: #b0bec5 !important;
}

.text-blue-gray-A400, .text-blue-grey-A400 {
  color: #78909c !important;
}

.text-blue-gray-A700, .text-blue-grey-A700 {
  color: #455a64 !important;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}

.text-light {
  color: white !important;
}

.text-dark {
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-50 {
  background-color: #ffebee !important;
}

.bg-red-100 {
  background-color: #ffcdd2 !important;
}

.bg-red-200 {
  background-color: #ef9a9a !important;
}

.bg-red-300 {
  background-color: #e57373 !important;
}

.bg-red-400 {
  background-color: #ef5350 !important;
}

.bg-red-500 {
  background-color: #f44336 !important;
}

.bg-red-600 {
  background-color: #e53935 !important;
}

.bg-red-700 {
  background-color: #d32f2f !important;
}

.bg-red-800 {
  background-color: #c62828 !important;
}

.bg-red-900 {
  background-color: #b71c1c !important;
}

.bg-red-A100 {
  background-color: #ff8a80 !important;
}

.bg-red-A200 {
  background-color: #ff5252 !important;
}

.bg-red-A400 {
  background-color: #ff1744 !important;
}

.bg-red-A700 {
  background-color: #d50000 !important;
}

.bg-pink-50 {
  background-color: #fce4ec !important;
}

.bg-pink-100 {
  background-color: #f8bbd0 !important;
}

.bg-pink-200 {
  background-color: #f48fb1 !important;
}

.bg-pink-300 {
  background-color: #f06292 !important;
}

.bg-pink-400 {
  background-color: #ec407a !important;
}

.bg-pink-500 {
  background-color: #e91e63 !important;
}

.bg-pink-600 {
  background-color: #d81b60 !important;
}

.bg-pink-700 {
  background-color: #c2185b !important;
}

.bg-pink-800 {
  background-color: #ad1457 !important;
}

.bg-pink-900 {
  background-color: #880e4f !important;
}

.bg-pink-A100 {
  background-color: #ff80ab !important;
}

.bg-pink-A200 {
  background-color: #ff4081 !important;
}

.bg-pink-A400 {
  background-color: #f50057 !important;
}

.bg-pink-A700 {
  background-color: #c51162 !important;
}

.bg-purple-50 {
  background-color: #f3e5f5 !important;
}

.bg-purple-100 {
  background-color: #e1bee7 !important;
}

.bg-purple-200 {
  background-color: #ce93d8 !important;
}

.bg-purple-300 {
  background-color: #ba68c8 !important;
}

.bg-purple-400 {
  background-color: #ab47bc !important;
}

.bg-purple-500 {
  background-color: #9c27b0 !important;
}

.bg-purple-600 {
  background-color: #8e24aa !important;
}

.bg-purple-700 {
  background-color: #7b1fa2 !important;
}

.bg-purple-800 {
  background-color: #6a1b9a !important;
}

.bg-purple-900 {
  background-color: #4a148c !important;
}

.bg-purple-A100 {
  background-color: #ea80fc !important;
}

.bg-purple-A200 {
  background-color: #e040fb !important;
}

.bg-purple-A400 {
  background-color: #d500f9 !important;
}

.bg-purple-A700 {
  background-color: #aa00ff !important;
}

.bg-deep-purple-50 {
  background-color: #ede7f6 !important;
}

.bg-deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.bg-deep-purple-200 {
  background-color: #b39ddb !important;
}

.bg-deep-purple-300 {
  background-color: #9575cd !important;
}

.bg-deep-purple-400 {
  background-color: #7e57c2 !important;
}

.bg-deep-purple-500 {
  background-color: #673ab7 !important;
}

.bg-deep-purple-600 {
  background-color: #5e35b1 !important;
}

.bg-deep-purple-700 {
  background-color: #512da8 !important;
}

.bg-deep-purple-800 {
  background-color: #4527a0 !important;
}

.bg-deep-purple-900 {
  background-color: #311b92 !important;
}

.bg-deep-purple-A100 {
  background-color: #b388ff !important;
}

.bg-deep-purple-A200 {
  background-color: #7c4dff !important;
}

.bg-deep-purple-A400 {
  background-color: #651fff !important;
}

.bg-deep-purple-A700 {
  background-color: #6200ea !important;
}

.bg-indigo-50 {
  background-color: #e8eaf6 !important;
}

.bg-indigo-100 {
  background-color: #c5cae9 !important;
}

.bg-indigo-200 {
  background-color: #9fa8da !important;
}

.bg-indigo-300 {
  background-color: #7986cb !important;
}

.bg-indigo-400 {
  background-color: #5c6bc0 !important;
}

.bg-indigo-500 {
  background-color: #3f51b5 !important;
}

.bg-indigo-600 {
  background-color: #3949ab !important;
}

.bg-indigo-700 {
  background-color: #303f9f !important;
}

.bg-indigo-800 {
  background-color: #283593 !important;
}

.bg-indigo-900 {
  background-color: #1a237e !important;
}

.bg-indigo-A100 {
  background-color: #8c9eff !important;
}

.bg-indigo-A200 {
  background-color: #536dfe !important;
}

.bg-indigo-A400 {
  background-color: #3d5afe !important;
}

.bg-indigo-A700 {
  background-color: #304ffe !important;
}

.bg-blue-50 {
  background-color: #e3f2fd !important;
}

.bg-blue-100 {
  background-color: #bbdefb !important;
}

.bg-blue-200 {
  background-color: #90caf9 !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
}

.bg-blue-400 {
  background-color: #42a5f5 !important;
}

.bg-blue-500 {
  background-color: #2196f3 !important;
}

.bg-blue-600 {
  background-color: #1e88e5 !important;
}

.bg-blue-700 {
  background-color: #1976d2 !important;
}

.bg-blue-800 {
  background-color: #1565c0 !important;
}

.bg-blue-900 {
  background-color: #0d47a1 !important;
}

.bg-blue-A100 {
  background-color: #82b1ff !important;
}

.bg-blue-A200 {
  background-color: #448aff !important;
}

.bg-blue-A400 {
  background-color: #2979ff !important;
}

.bg-blue-A700 {
  background-color: #2962ff !important;
}

.bg-light-blue-50 {
  background-color: #e1f5fe !important;
}

.bg-light-blue-100 {
  background-color: #b3e5fc !important;
}

.bg-light-blue-200 {
  background-color: #81d4fa !important;
}

.bg-light-blue-300 {
  background-color: #4fc3f7 !important;
}

.bg-light-blue-400 {
  background-color: #29b6f6 !important;
}

.bg-light-blue-500 {
  background-color: #03a9f4 !important;
}

.bg-light-blue-600 {
  background-color: #039be5 !important;
}

.bg-light-blue-700 {
  background-color: #0288d1 !important;
}

.bg-light-blue-800 {
  background-color: #0277bd !important;
}

.bg-light-blue-900 {
  background-color: #01579b !important;
}

.bg-light-blue-A100 {
  background-color: #80d8ff !important;
}

.bg-light-blue-A200 {
  background-color: #40c4ff !important;
}

.bg-light-blue-A400 {
  background-color: #00b0ff !important;
}

.bg-light-blue-A700 {
  background-color: #0091ea !important;
}

.bg-cyan-50 {
  background-color: #e0f7fa !important;
}

.bg-cyan-100 {
  background-color: #b2ebf2 !important;
}

.bg-cyan-200 {
  background-color: #80deea !important;
}

.bg-cyan-300 {
  background-color: #4dd0e1 !important;
}

.bg-cyan-400 {
  background-color: #26c6da !important;
}

.bg-cyan-500 {
  background-color: #00bcd4 !important;
}

.bg-cyan-600 {
  background-color: #00acc1 !important;
}

.bg-cyan-700 {
  background-color: #0097a7 !important;
}

.bg-cyan-800 {
  background-color: #00838f !important;
}

.bg-cyan-900 {
  background-color: #006064 !important;
}

.bg-cyan-A100 {
  background-color: #84ffff !important;
}

.bg-cyan-A200 {
  background-color: #18ffff !important;
}

.bg-cyan-A400 {
  background-color: #00e5ff !important;
}

.bg-cyan-A700 {
  background-color: #00b8d4 !important;
}

.bg-teal-50 {
  background-color: #e0f2f1 !important;
}

.bg-teal-100 {
  background-color: #b2dfdb !important;
}

.bg-teal-200 {
  background-color: #80cbc4 !important;
}

.bg-teal-300 {
  background-color: #4db6ac !important;
}

.bg-teal-400 {
  background-color: #26a69a !important;
}

.bg-teal-500 {
  background-color: #009688 !important;
}

.bg-teal-600 {
  background-color: #00897b !important;
}

.bg-teal-700 {
  background-color: #00796b !important;
}

.bg-teal-800 {
  background-color: #00695c !important;
}

.bg-teal-900 {
  background-color: #004d40 !important;
}

.bg-teal-A100 {
  background-color: #a7ffeb !important;
}

.bg-teal-A200 {
  background-color: #64ffda !important;
}

.bg-teal-A400 {
  background-color: #1de9b6 !important;
}

.bg-teal-A700 {
  background-color: #00bfa5 !important;
}

.bg-green-50 {
  background-color: #e8f5e9 !important;
}

.bg-green-100 {
  background-color: #c8e6c9 !important;
}

.bg-green-200 {
  background-color: #a5d6a7 !important;
}

.bg-green-300 {
  background-color: #81c784 !important;
}

.bg-green-400 {
  background-color: #66bb6a !important;
}

.bg-green-500 {
  background-color: #4caf50 !important;
}

.bg-green-600 {
  background-color: #43a047 !important;
}

.bg-green-700 {
  background-color: #388e3c !important;
}

.bg-green-800 {
  background-color: #2e7d32 !important;
}

.bg-green-900 {
  background-color: #1b5e20 !important;
}

.bg-green-A100 {
  background-color: #b9f6ca !important;
}

.bg-green-A200 {
  background-color: #69f0ae !important;
}

.bg-green-A400 {
  background-color: #00e676 !important;
}

.bg-green-A700 {
  background-color: #00c853 !important;
}

.bg-light-green-50 {
  background-color: #f1f8e9 !important;
}

.bg-light-green-100 {
  background-color: #dcedc8 !important;
}

.bg-light-green-200 {
  background-color: #c5e1a5 !important;
}

.bg-light-green-300 {
  background-color: #aed581 !important;
}

.bg-light-green-400 {
  background-color: #9ccc65 !important;
}

.bg-light-green-500 {
  background-color: #8bc34a !important;
}

.bg-light-green-600 {
  background-color: #7cb342 !important;
}

.bg-light-green-700 {
  background-color: #689f38 !important;
}

.bg-light-green-800 {
  background-color: #558b2f !important;
}

.bg-light-green-900 {
  background-color: #33691e !important;
}

.bg-light-green-A100 {
  background-color: #ccff90 !important;
}

.bg-light-green-A200 {
  background-color: #b2ff59 !important;
}

.bg-light-green-A400 {
  background-color: #76ff03 !important;
}

.bg-light-green-A700 {
  background-color: #64dd17 !important;
}

.bg-lime-50 {
  background-color: #f9fbe7 !important;
}

.bg-lime-100 {
  background-color: #f0f4c3 !important;
}

.bg-lime-200 {
  background-color: #e6ee9c !important;
}

.bg-lime-300 {
  background-color: #dce775 !important;
}

.bg-lime-400 {
  background-color: #d4e157 !important;
}

.bg-lime-500 {
  background-color: #cddc39 !important;
}

.bg-lime-600 {
  background-color: #c0ca33 !important;
}

.bg-lime-700 {
  background-color: #afb42b !important;
}

.bg-lime-800 {
  background-color: #9e9d24 !important;
}

.bg-lime-900 {
  background-color: #827717 !important;
}

.bg-lime-A100 {
  background-color: #f4ff81 !important;
}

.bg-lime-A200 {
  background-color: #eeff41 !important;
}

.bg-lime-A400 {
  background-color: #c6ff00 !important;
}

.bg-lime-A700 {
  background-color: #aeea00 !important;
}

.bg-yellow-50 {
  background-color: #fffde7 !important;
}

.bg-yellow-100 {
  background-color: #fff9c4 !important;
}

.bg-yellow-200 {
  background-color: #fff59d !important;
}

.bg-yellow-300 {
  background-color: #fff176 !important;
}

.bg-yellow-400 {
  background-color: #ffee58 !important;
}

.bg-yellow-500 {
  background-color: #ffeb3b !important;
}

.bg-yellow-600 {
  background-color: #fdd835 !important;
}

.bg-yellow-700 {
  background-color: #fbc02d !important;
}

.bg-yellow-800 {
  background-color: #f9a825 !important;
}

.bg-yellow-900 {
  background-color: #f57f17 !important;
}

.bg-yellow-A100 {
  background-color: #ffff8d !important;
}

.bg-yellow-A200 {
  background-color: #ffff00 !important;
}

.bg-yellow-A400 {
  background-color: #ffea00 !important;
}

.bg-yellow-A700 {
  background-color: #ffd600 !important;
}

.bg-amber-50 {
  background-color: #fff8e1 !important;
}

.bg-amber-100 {
  background-color: #ffecb3 !important;
}

.bg-amber-200 {
  background-color: #ffe082 !important;
}

.bg-amber-300 {
  background-color: #ffd54f !important;
}

.bg-amber-400 {
  background-color: #ffca28 !important;
}

.bg-amber-500 {
  background-color: #ffc107 !important;
}

.bg-amber-600 {
  background-color: #ffb300 !important;
}

.bg-amber-700 {
  background-color: #ffa000 !important;
}

.bg-amber-800 {
  background-color: #ff8f00 !important;
}

.bg-amber-900 {
  background-color: #ff6f00 !important;
}

.bg-amber-A100 {
  background-color: #ffe57f !important;
}

.bg-amber-A200 {
  background-color: #ffd740 !important;
}

.bg-amber-A400 {
  background-color: #ffc400 !important;
}

.bg-amber-A700 {
  background-color: #ffab00 !important;
}

.bg-orange-50 {
  background-color: #fff3e0 !important;
}

.bg-orange-100 {
  background-color: #ffe0b2 !important;
}

.bg-orange-200 {
  background-color: #ffcc80 !important;
}

.bg-orange-300 {
  background-color: #ffb74d !important;
}

.bg-orange-400 {
  background-color: #ffa726 !important;
}

.bg-orange-500 {
  background-color: #ff9800 !important;
}

.bg-orange-600 {
  background-color: #fb8c00 !important;
}

.bg-orange-700 {
  background-color: #f57c00 !important;
}

.bg-orange-800 {
  background-color: #ef6c00 !important;
}

.bg-orange-900 {
  background-color: #e65100 !important;
}

.bg-orange-A100 {
  background-color: #ffd180 !important;
}

.bg-orange-A200 {
  background-color: #ffab40 !important;
}

.bg-orange-A400 {
  background-color: #ff9100 !important;
}

.bg-orange-A700 {
  background-color: #ff6d00 !important;
}

.bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.bg-deep-orange-100 {
  background-color: #ffccbc !important;
}

.bg-deep-orange-200 {
  background-color: #ffab91 !important;
}

.bg-deep-orange-300 {
  background-color: #ff8a65 !important;
}

.bg-deep-orange-400 {
  background-color: #ff7043 !important;
}

.bg-deep-orange-500 {
  background-color: #ff5722 !important;
}

.bg-deep-orange-600 {
  background-color: #f4511e !important;
}

.bg-deep-orange-700 {
  background-color: #e64a19 !important;
}

.bg-deep-orange-800 {
  background-color: #d84315 !important;
}

.bg-deep-orange-900 {
  background-color: #bf360c !important;
}

.bg-deep-orange-A100 {
  background-color: #ff9e80 !important;
}

.bg-deep-orange-A200 {
  background-color: #ff6e40 !important;
}

.bg-deep-orange-A400 {
  background-color: #ff3d00 !important;
}

.bg-deep-orange-A700 {
  background-color: #dd2c00 !important;
}

.bg-brown-50 {
  background-color: #efebe9 !important;
}

.bg-brown-100 {
  background-color: #d7ccc8 !important;
}

.bg-brown-200 {
  background-color: #bcaaa4 !important;
}

.bg-brown-300 {
  background-color: #a1887f !important;
}

.bg-brown-400 {
  background-color: #8d6e63 !important;
}

.bg-brown-500 {
  background-color: #795548 !important;
}

.bg-brown-600 {
  background-color: #6d4c41 !important;
}

.bg-brown-700 {
  background-color: #5d4037 !important;
}

.bg-brown-800 {
  background-color: #4e342e !important;
}

.bg-brown-900 {
  background-color: #3e2723 !important;
}

.bg-brown-A100 {
  background-color: #d7ccc8 !important;
}

.bg-brown-A200 {
  background-color: #bcaaa4 !important;
}

.bg-brown-A400 {
  background-color: #8d6e63 !important;
}

.bg-brown-A700 {
  background-color: #5d4037 !important;
}

.bg-gray-50 {
  background-color: #fafafa !important;
}

.bg-gray-100 {
  background-color: #f5f5f5 !important;
}

.bg-gray-200 {
  background-color: #eeeeee !important;
}

.bg-gray-300 {
  background-color: #e0e0e0 !important;
}

.bg-gray-400 {
  background-color: #bdbdbd !important;
}

.bg-gray-500 {
  background-color: #9e9e9e !important;
}

.bg-gray-600 {
  background-color: #757575 !important;
}

.bg-gray-700 {
  background-color: #616161 !important;
}

.bg-gray-800 {
  background-color: #424242 !important;
}

.bg-gray-900 {
  background-color: #212121 !important;
}

.bg-gray-A100 {
  background-color: #ffffff !important;
}

.bg-gray-A200 {
  background-color: #eeeeee !important;
}

.bg-gray-A400 {
  background-color: #bdbdbd !important;
}

.bg-gray-A700 {
  background-color: #616161 !important;
}

.bg-blue-gray-50, .bg-blue-grey-50 {
  background-color: #eceff1 !important;
}

.bg-blue-gray-100, .bg-blue-grey-100 {
  background-color: #cfd8dc !important;
}

.bg-blue-gray-200, .bg-blue-grey-200 {
  background-color: #b0bec5 !important;
}

.bg-blue-gray-300, .bg-blue-grey-300 {
  background-color: #90a4ae !important;
}

.bg-blue-gray-400, .bg-blue-grey-400 {
  background-color: #78909c !important;
}

.bg-blue-gray-500, .bg-blue-grey-500 {
  background-color: #607d8b !important;
}

.bg-blue-gray-600, .bg-blue-grey-600 {
  background-color: #546e7a !important;
}

.bg-blue-gray-700, .bg-blue-grey-700 {
  background-color: #455a64 !important;
}

.bg-blue-gray-800, .bg-blue-grey-800 {
  background-color: #37474f !important;
}

.bg-blue-gray-900, .bg-blue-grey-900 {
  background-color: #263238 !important;
}

.bg-blue-gray-A100, .bg-blue-grey-A100 {
  background-color: #cfd8dc !important;
}

.bg-blue-gray-A200, .bg-blue-grey-A200 {
  background-color: #b0bec5 !important;
}

.bg-blue-gray-A400, .bg-blue-grey-A400 {
  background-color: #78909c !important;
}

.bg-blue-gray-A700, .bg-blue-grey-A700 {
  background-color: #455a64 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-black {
  background-color: black !important;
}

.bg-light {
  background-color: white !important;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.text-main {
  color: #44034d !important;
}

.bg-main {
  background-color: #44034d !important;
}

.icon-10 {
  width: 10px !important;
  height: 10px !important;
  font-size: 10px !important;
  line-height: 10px !important;
}

.icon-12 {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  line-height: 12px !important;
}

.icon-14 {
  width: 14px !important;
  height: 14px !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.icon-16 {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.icon-18 {
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
  line-height: 18px !important;
}

.icon-20 {
  width: 20px !important;
  height: 20px !important;
  font-size: 20px !important;
  line-height: 20px !important;
}

.icon-22 {
  width: 22px !important;
  height: 22px !important;
  font-size: 22px !important;
  line-height: 22px !important;
}

.icon-24 {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  line-height: 24px !important;
}

.icon-26 {
  width: 26px !important;
  height: 26px !important;
  font-size: 26px !important;
  line-height: 26px !important;
}

.icon-28 {
  width: 28px !important;
  height: 28px !important;
  font-size: 28px !important;
  line-height: 28px !important;
}

.icon-30 {
  width: 30px !important;
  height: 30px !important;
  font-size: 30px !important;
  line-height: 30px !important;
}

.icon-32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 32px !important;
  line-height: 32px !important;
}

.icon-34 {
  width: 34px !important;
  height: 34px !important;
  font-size: 34px !important;
  line-height: 34px !important;
}

.icon-36 {
  width: 36px !important;
  height: 36px !important;
  font-size: 36px !important;
  line-height: 36px !important;
}

.icon-48 {
  width: 48px !important;
  height: 48px !important;
  font-size: 48px !important;
  line-height: 48px !important;
}

.fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-inherit {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

.text-ellipsis,
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.align-text {
  text-align: left;
}
[dir=rtl] .align-text {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-auto {
  margin: auto !important;
}

.m-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-x-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-x-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-x-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-x-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-x-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.m-x-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-y-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-y-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-y-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-y-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-y-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-y-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.m-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.m-r-48 {
  margin-right: 48px !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.m-l-48 {
  margin-left: 48px !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m--4 {
  margin: -4px !important;
}

.m--8 {
  margin: -8px !important;
}

.m--16 {
  margin: -16px !important;
}

.m--24 {
  margin: -24px !important;
}

.m--32 {
  margin: -32px !important;
}

.m--48 {
  margin: -48px !important;
}

.m-x--4 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.m-x--8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.m-x--16 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.m-x--24 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.m-x--32 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.m-x--48 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.m-y--4 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.m-y--8 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.m-y--16 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.m-y--24 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.m-y--32 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.m-y--48 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.m-t--4 {
  margin-top: -4px !important;
}

.m-t--8 {
  margin-top: -8px !important;
}

.m-t--16 {
  margin-top: -16px !important;
}

.m-t--24 {
  margin-top: -24px !important;
}

.m-t--32 {
  margin-top: -32px !important;
}

.m-t--48 {
  margin-top: -48px !important;
}

.m-r--4 {
  margin-right: -4px !important;
}

.m-r--8 {
  margin-right: -8px !important;
}

.m-r--16 {
  margin-right: -16px !important;
}

.m-r--24 {
  margin-right: -24px !important;
}

.m-r--32 {
  margin-right: -32px !important;
}

.m-r--48 {
  margin-right: -48px !important;
}

.m-b--4 {
  margin-bottom: -4px !important;
}

.m-b--8 {
  margin-bottom: -8px !important;
}

.m-b--16 {
  margin-bottom: -16px !important;
}

.m-b--24 {
  margin-bottom: -24px !important;
}

.m-b--32 {
  margin-bottom: -32px !important;
}

.m-b--48 {
  margin-bottom: -48px !important;
}

.m-l--4 {
  margin-left: -4px !important;
}

.m-l--8 {
  margin-left: -8px !important;
}

.m-l--16 {
  margin-left: -16px !important;
}

.m-l--24 {
  margin-left: -24px !important;
}

.m-l--32 {
  margin-left: -32px !important;
}

.m-l--48 {
  margin-left: -48px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-x-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-x-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-x-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-x-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-x-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.p-x-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-y-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-y-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-y-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-y-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-y-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-y-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.p-r-48 {
  padding-right: 48px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.p-l-48 {
  padding-left: 48px !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-full {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-min-content {
  width: min-content !important;
}

.w-max-content {
  width: max-content !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-full {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-min-content {
  height: min-content !important;
}

.h-max-content {
  height: max-content !important;
}

.r-0 {
  border-radius: 0 !important;
}

.r-4 {
  border-radius: 4px !important;
}

.r-8 {
  border-radius: 8px !important;
}

.r-16 {
  border-radius: 16px !important;
}

.r-24 {
  border-radius: 24px !important;
}

.r-32 {
  border-radius: 32px !important;
}

.r-full {
  border-radius: 9999px !important;
}

.r-t-l-0 {
  border-top-left-radius: 0 !important;
}

.r-t-l-4 {
  border-top-left-radius: 4px !important;
}

.r-t-l-8 {
  border-top-left-radius: 8px !important;
}

.r-t-l-16 {
  border-top-left-radius: 16px !important;
}

.r-t-l-24 {
  border-top-left-radius: 24px !important;
}

.r-t-l-32 {
  border-top-left-radius: 32px !important;
}

.r-t-l-full {
  border-top-left-radius: 9999px !important;
}

.r-t-r-0 {
  border-top-right-radius: 0 !important;
}

.r-t-r-4 {
  border-top-right-radius: 4px !important;
}

.r-t-r-8 {
  border-top-right-radius: 8px !important;
}

.r-t-r-16 {
  border-top-right-radius: 16px !important;
}

.r-t-r-24 {
  border-top-right-radius: 24px !important;
}

.r-t-r-32 {
  border-top-right-radius: 32px !important;
}

.r-t-r-full {
  border-top-right-radius: 9999px !important;
}

.r-b-r-0 {
  border-bottom-right-radius: 0 !important;
}

.r-b-r-4 {
  border-bottom-right-radius: 4px !important;
}

.r-b-r-8 {
  border-bottom-right-radius: 8px !important;
}

.r-b-r-16 {
  border-bottom-right-radius: 16px !important;
}

.r-b-r-24 {
  border-bottom-right-radius: 24px !important;
}

.r-b-r-32 {
  border-bottom-right-radius: 32px !important;
}

.r-b-r-full {
  border-bottom-right-radius: 9999px !important;
}

.r-b-l-0 {
  border-bottom-left-radius: 0 !important;
}

.r-b-l-4 {
  border-bottom-left-radius: 4px !important;
}

.r-b-l-8 {
  border-bottom-left-radius: 8px !important;
}

.r-b-l-16 {
  border-bottom-left-radius: 16px !important;
}

.r-b-l-24 {
  border-bottom-left-radius: 24px !important;
}

.r-b-l-32 {
  border-bottom-left-radius: 32px !important;
}

.r-b-l-full {
  border-bottom-left-radius: 9999px !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.f-w-100 {
  font-weight: 100 !important;
}

.f-w-200 {
  font-weight: 200 !important;
}

.f-w-300 {
  font-weight: 300 !important;
}

.f-w-400 {
  font-weight: 400 !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

.f-w-800 {
  font-weight: 800 !important;
}

.f-w-900 {
  font-weight: 900 !important;
}

.f-s-0 {
  font-size: 0 !important;
}

.f-s-10 {
  font-size: 10px !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-s-16 {
  font-size: 16px !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-20 {
  font-size: 20px !important;
}

.l-h-0 {
  line-height: 0 !important;
}

.l-h-10 {
  line-height: 10px !important;
}

.l-h-12 {
  line-height: 12px !important;
}

.l-h-14 {
  line-height: 14px !important;
}

.l-h-16 {
  line-height: 16px !important;
}

.l-h-18 {
  line-height: 18px !important;
}

.l-h-20 {
  line-height: 20px !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline {
  display: inline !important;
}

.d-none {
  display: none !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-copy {
  cursor: copy !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.b-0 {
  border: 0 !important;
}

.b-1 {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.b-2 {
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.b-3 {
  border: 3px solid rgba(0, 0, 0, 0.12) !important;
}

.b-4 {
  border: 4px solid rgba(0, 0, 0, 0.12) !important;
}

.b-5 {
  border: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.b-t-0 {
  border-top: 0 !important;
}

.b-t-1 {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.b-t-2 {
  border-top: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.b-t-3 {
  border-top: 3px solid rgba(0, 0, 0, 0.12) !important;
}

.b-t-4 {
  border-top: 4px solid rgba(0, 0, 0, 0.12) !important;
}

.b-t-5 {
  border-top: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.b-b-0 {
  border-bottom: 0 !important;
}

.b-b-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.b-b-2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.b-b-3 {
  border-bottom: 3px solid rgba(0, 0, 0, 0.12) !important;
}

.b-b-4 {
  border-bottom: 4px solid rgba(0, 0, 0, 0.12) !important;
}

.b-b-5 {
  border-bottom: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.b-r-0 {
  border-right: 0 !important;
}

.b-r-1 {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.b-r-2 {
  border-right: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.b-r-3 {
  border-right: 3px solid rgba(0, 0, 0, 0.12) !important;
}

.b-r-4 {
  border-right: 4px solid rgba(0, 0, 0, 0.12) !important;
}

.b-r-5 {
  border-right: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.b-l-0 {
  border-left: 0 !important;
}

.b-l-1 {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.b-l-2 {
  border-left: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.b-l-3 {
  border-left: 3px solid rgba(0, 0, 0, 0.12) !important;
}

.b-l-4 {
  border-left: 4px solid rgba(0, 0, 0, 0.12) !important;
}

.b-l-5 {
  border-left: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.toast-container .ngx-toastr {
  min-height: 48px;
  padding: 14px 16px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.toast-container .toast-success,
.toast-container .toast-info,
.toast-container .toast-warning,
.toast-container .toast-error {
  padding-left: 50px;
  color: #fff;
}
.toast-container .toast-success {
  background-color: #4caf50;
}
.toast-container .toast-info {
  background-color: #36003d;
}
.toast-container .toast-warning {
  background-color: #ff9800;
}
.toast-container .toast-error {
  background-color: #f44336;
}
.toast-container .toast-close-button {
  font-size: inherit;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.toast-container .toast-close-button:hover {
  color: inherit;
  opacity: 0.6;
}

.mat-card {
  margin-bottom: 16px;
}

.mat-accordion {
  display: block;
  margin-bottom: 16px;
}

.mat-expansion-panel-header-description {
  flex-basis: 0;
  justify-content: space-between;
  align-items: center;
}

.mat-menu-panel {
  max-width: 400px !important;
}

.mat-cell,
.mat-checkbox-layout {
  white-space: pre-wrap !important;
  word-wrap: break-word;
}

.mat-list-base {
  position: relative;
}

.form-field-full .mat-form-field {
  width: 100%;
}

.snack-error {
  background-color: #853831;
}

.snack-success {
  background-color: #2f8047;
}

.mat-tooltip {
  background-color: #2d0033 !important;
  font-size: 13px !important;
}

.p-datepicker-inline {
  display: block !important;
}

.p-datatable.ms-datatable .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}
.p-datatable.ms-datatable .p-datatable-thead > tr > th {
  text-align: left;
}
.p-datatable.ms-datatable .p-datatable-tbody > tr > td {
  cursor: auto;
}
.p-datatable.ms-datatable .p-paginator {
  padding: 1rem;
}
.p-datatable.ms-datatable .p-paginator .p-paginator-current {
  margin-left: auto;
}

/* Responsive */
.ms-datatable .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media (max-width: 599px) {
  .p-datatable.ms-datatable .p-datatable-thead > tr > th,
.p-datatable.ms-datatable .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .p-datatable.ms-datatable .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--layer-2);
  }
  .p-datatable.ms-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  .p-datatable.ms-datatable .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
}