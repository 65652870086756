@import "../helpers/_mixins";

.mat-icon {
  text-align: center;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  min-height: 1em;
}

.ms-svg-icon {
  display: inline-block;
  text-align: center;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  min-height: 1em;

  line-height: 1em;
  font-size: inherit;
  vertical-align: -0.125em;
}
