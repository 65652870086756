@import "../mixins/badge";

.badge {
  @include badge();
}

.badge-r {
  background: #882898;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
