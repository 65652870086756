///////////////////////////////////
// LAYOUT
///////////////////////////////////
@import "../variables";
@import "../mixins/breakpoints";

.m-main {
  margin: 16px;

  @include bp-lt(medium) {
    margin: 0px;
  }
}

.ms-container-wrap,
.ms-container {
  height: 100%;
}

.ms-content {
  position: relative;
  padding: $gutter;
}

// TODO: Grid row
.ms-row {
  margin-right: -$gutter/2;
  margin-left: -$gutter/2;
}

// TODO: Grid col
.ms-col {
  padding-right: $gutter/2;
  padding-left: $gutter/2;
}

// Layout control
.ms-header-above {
  .ms-container {
    height: calc(100% - #{$toolbar-height-desktop}) !important;
  }

  .ms-sidebar-main {
    height: 100% !important;
  }
}

// Fix the init content width
.ms-content-width-fix {
  .ms-content-wrap {
    margin-left: $sidenav-width !important;

    [dir="rtl"] & {
      margin-left: auto !important;
      margin-right: $sidenav-width !important;
    }
  }
}
