@import "../helpers/_mixins";

///////////////////////////////////
// ICONS
///////////////////////////////////

// Icon size

$icon-sizes: (10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 48); //dp

@each $size in $icon-sizes {
  .#{"icon" + "-" + $size} {
    @include icon($size);
  }
}
