@import "__functions";
@import "__variables";
@import "__mixins";
@import "alignment";
@import "border";
@import "colors";
@import "cursor";
@import "display";
@import "fonts";
@import "icons";
@import "image";
@import "position";
@import "radius";
@import "size";
@import "spacing";
@import "text";

// Generate Helpers
@each $key, $utility in $utilities {
  $values: map-get($utility, values);

  @each $class-modifier, $value in $values {
    $properties: map-get($utility, property);
    $property-class-prefix: map-get($utility, class);
    $property-class-prefix-hyphen: if(
      $property-class-prefix== "",
      $property-class-prefix,
      $property-class-prefix + "-"
    );

    @if type-of($properties) == "string" {
      $properties: append((), $properties);
    }

    .#{$property-class-prefix-hyphen + $class-modifier} {
      @each $property in $properties {
        #{$property}: $value !important;
      }
    }
  }
}
