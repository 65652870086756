////////////////////////////////////
// Material components style fix
////////////////////////////////////
@import "../variables";

.mat-card {
  margin-bottom: $gutter;
}

.mat-accordion {
  display: block;
  margin-bottom: $gutter;
}

.mat-expansion-panel-header-description {
  flex-basis: 0;
  justify-content: space-between;
  align-items: center;
}

.mat-menu-panel {
  max-width: 400px !important;
}

.mat-cell,
.mat-checkbox-layout {
  white-space: pre-wrap !important;
  word-wrap: break-word;
}

.mat-list-base {
  position: relative;
}

.form-field-full {
  .mat-form-field {
    width: 100%;
  }
}

.snack-error {
  background-color: rgb(133, 56, 49);
}

.snack-success {
  background-color: rgb(47, 128, 71);
}

.mat-tooltip {
  background-color: #2d0033 !important;
  font-size: 13px !important;
}
