///////////////////////////////////
// NAVLIST
///////////////////////////////////
@use '~@angular/material' as mat;
@import "../variables";

.mat-nav-list {
  > a {
    &.active {
      color: mat.get-color-from-palette(map-get($theme, accent));
    }
  }
}
