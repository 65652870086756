///////////////////////////////////
// TEXT
///////////////////////////////////

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-inherit {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

.text-ellipsis,
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.align-text {
  text-align: left;

  [dir="rtl"] & {
    text-align: right;
  }
}

$text: (
  "text-transform": (
    property: text-transform,
    class: text,
    values: (
      capitalize: capitalize,
      uppercase: uppercase,
      lowercase: lowercase,
    ),
  ),
  "text-align": (
    property: text-align,
    class: text,
    values: (
      center: center,
      right: right,
      left: left,
    ),
  ),
  "white-space": (
    property: white-space,
    class: text,
    values: (
      wrap: normal,
      nowrap: nowrap,
    ),
  ),
);

$utilities: map-merge($text, $utilities);
